import Landing from "../components/Landing";
import ProductCard from "../components/ProductCard";
import AnchorLink from "react-anchor-link-smooth-scroll";

import conitos from "../assets/conitos.png";
import alfa from "../assets/alfa.png";
import mini from "../assets/mini.png";
import { Link } from "react-router-dom";
import { useProducts } from "../api/useProducts";

const products = [
  {
    nombre: "Conitos",
    descripcion:
      "Conito de dulce de leche con base de galletita de cacao suave bañado en chocolate semiamargo o chocolate blanco",
    image: conitos,
  },
  {
    nombre: "Alfajores",
    descripcion: (
      <AnchorLink
        offset="115"
        href="#variedades"
        className="btn py-2 mt-3 mx-auto btn-primary"
      >
        Ver variedades
      </AnchorLink>
    ),
    image: alfa,
  },
  {
    nombre: "Mini alfajores",
    descripcion:
      "Masa de cacao suave relleno de dulce de leche aromatizado con esencia de licor y baño blanco/negro",
    image: mini,
  },
];

export default function Home() {
  const { data: menu, isLoading, isError } = useProducts();

  if (isLoading || isError) {
    return null;
  }

  return (
    <>
      <section id="inicio">
        <Landing />
      </section>

      <div className="container">
        <section id="productos">
          <h2>Nuestros Productos</h2>
          <div className="row justify-content-center">
            {products.map((p, i) => (
              <ProductCard key={i} producto={p} image={p.image} />
            ))}
          </div>
        </section>
      </div>

      <div className="container">
        <section id="variedades">
          {menu.map((category) => (
            <div key={category.id}>
              <h2>{category.nombre}</h2>
              <div className="row justify-content-center">
                {category.productos.map((producto) => (
                  <ProductCard
                    key={producto.id}
                    producto={producto}
                    image={`/assets/${producto.id}_h.png`}
                  />
                ))}
              </div>
            </div>
          ))}
        </section>

        <div align="center">
          <Link to="/productos" className="btn btn-primary">
            Ver presentaciones
          </Link>
        </div>
      </div>
    </>
  );
}
