import Contact from "./Contact";

import AnchorLink from "react-anchor-link-smooth-scroll";

const Landing = () => {
  return (
    <div className="landing-container">
      <div className="landing-block">
        <h1>Sintonia</h1>
        <span class="tag-label">ES FELICIDAD</span>
        <p className="lead">Elaboración de alfajores artesanales</p>
        <Contact />
        <AnchorLink offset="115" href="#productos">
          Ver productos
        </AnchorLink>
      </div>
    </div>
  );
};

export default Landing;
