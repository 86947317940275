import { useMenu } from "../api/useProducts";
import ProductCard from "../components/ProductCard";

export default function Productos() {
  const { data: menu, isLoading, isError } = useMenu();

  if (isLoading || isError) {
    return null;
  }

  return (
    <section>
      <div
        className="py-5 d-flex justify-content-center"
        style={{
          backgroundImage: `url(${require("../assets/allfa.png")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="d-inline-flex flex-column bg-body p-2 px-5 rounded text-center">
          <h2 className="display-5 fw-bold">Productos</h2>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">
              Nuestros productos son artesanales, elaborados con ingredientes de
              primera calidad y sin conservantes.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <section id="variedades">
          {menu.map((category) => (
            <div key={category.id}>
              <h2>{category.nombre}</h2>
              <div className="row justify-content-center">
                {category.productos.map((producto) => (
                  <ProductCard
                    key={producto.id}
                    producto={producto}
                    image={`/assets/${producto.id}_top.png`}
                  />
                ))}
              </div>
            </div>
          ))}
        </section>
      </div>
    </section>
  );
}
