import { ReactComponent as InstagramIcon } from "../assets/instagram.svg";
import { ReactComponent as FacebookIcon } from "../assets/facebook.svg";

const Social = () => {
  return (
    <div className="d-flex gap-3">
      <a
        href={"https://www.instagram.com/sintoniaalfajoresartesanales/"}
        target="_blank"
        rel="noreferrer"
      >
        <InstagramIcon />
      </a>
      <a
        href={"https://www.facebook.com/Sintoniaalfajores/"}
        target="_blank"
        rel="noreferrer"
      >
        <FacebookIcon />
      </a>
    </div>
  );
};

export default Social;
