const ProductCard = ({ producto, image, subtitle, ...rest }) => {
  const { nombre, descripcion } = producto;

  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
      <div className="product-card" {...rest}>
        <img
          alt={`${nombre}`}
          className="img-fluid img-thumbnail"
          src={image}
        />
        {subtitle && <span>{subtitle}</span>}
        <h3>{nombre}</h3>
        <p>{descripcion}</p>
      </div>
    </div>
  );
};

export default ProductCard;
