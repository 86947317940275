import { useQuery } from "react-query";

const menuUrl = "/api/v1/sucursal/9/menu";

const getProducts = async () => {
  const response = await fetch(menuUrl);
  const data = await response.json();

  return data.filter((category) => [20, 21].includes(category.id));
};

export const useProducts = () => useQuery("products", getProducts);

const getMenu = async () => {
  const response = await fetch(menuUrl);
  return response.json();
};

export const useMenu = () => useQuery("menu", getMenu);
